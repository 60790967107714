<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-title>
      <span>{{ shopModelLocale.shopName }}</span>
    </v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" class="multi-col-validation mt-6" @submit.prevent="saveChanges">
        <v-row>
          <v-col md="4" cols="12">
            <v-text-field
              v-model="shopModelLocale.companyName"
              label="Merchant Name"
              dense
              outlined
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col md="4" cols="12">
            <v-text-field
              v-model="shopModelLocale.shopCode"
              label="Shop Code"
              dense
              outlined
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="shopModelLocale.shopUrl"
              dense
              label="Shop URL"
              outlined
              readonly
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="shopModelLocale.contactEmail"
              :rules="[validators.required, validators.emailValidator]"
              dense
              label="Contact E-mail"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="shopModelLocale.contactPhone" dense label="Contact Phone" outlined></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="shopModelLocale.contactName"
              :rules="[validators.required]"
              dense
              label="Contact Name"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="8">
            <v-text-field
              v-model="shopModelLocale.notificationUrl"
              dense
              label="Notification URL"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field
              v-model="shopModelLocale.notificationEmail"
              :rules="[validators.required, validators.emailValidator]"
              dense
              label="Notification E-mail"
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" :loading="loading" type="submit" :disabled="!valid">
              Save changes
            </v-btn>
            <v-btn color="secondary" outlined class="mt-4" @click="$router.back()"> Cancel </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, toRefs } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'

export default {
  props: {
    shopModel: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const shopModelLocale = toRefs(props).shopModel
    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const loading = ref(false)

    const emitErrorMessage = errors => {
      let msg = ''
      errors.forEach(element => {
        msg += `<li>${element.message}</li>`
      })
      emit('on-show-message', { message: msg, type: 'error' })
    }

    const saveChanges = () => {
      if (valid.value) {
        loading.value = true
        store
          .dispatch('onboarding-shops/update', shopModelLocale.value)
          .then(response => {
            emit('on-show-message', { message: 'Your changes have been saved.', type: 'info' })
          })
          .catch(e => {
            emitErrorMessage(e.request.response)
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        validate()
      }
    }

    return {
      shopModelLocale,
      saveChanges,
      emitErrorMessage,
      loading,
      valid,
      form,
      validate,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: { required, emailValidator },
    }
  },
}
</script>
