<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <shop-account
          :shop-model="shopModel"
          @on-show-message="showSnackbarMessage"
        ></shop-account>
      </v-tab-item>

      <v-tab-item>
        <shop-api-credentials
          :shop-model="shopModel"
          @copied-to-clipboard="showSnackbarMessage"
          @on-show-message="showSnackbarMessage"
        ></shop-api-credentials>
      </v-tab-item>
    </v-tabs-items>
    <v-snackbar
      v-model="snackbar.isSnackbarVisible"
      :timeout="2000"
    >
      <span class="text-center">{{ snackbar.snackbarMessage }}</span>
      <template v-slot:action="{ attrs }">
        <v-icon
          size="20"
          @click="snackbar.isSnackbarVisible = false"
        >
          {{ icons.mdiClose }}
        </v-icon>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
} from '@mdi/js'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'

// demos
import axios from '@axios'
import { useRouter } from '@core/utils'
import ShopAccount from './ShopAccount.vue'
import ShopApiCredentials from './ShopApiCredentials.vue'
import shopsStoreModule from '../../shopsStoreModule'
import store from '@/store'

export default {
  components: {
    ShopAccount,
    ShopApiCredentials,
  },
  setup() {
    const STORE_MODULE_NAME = 'onboarding-shops'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, shopsStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
    })

    const snackbar = ref({
      isSnackbarVisible: false,
      snackBarColor: 'info',
      snackbarMessage: '',
      snackbarTimeOut: '3000',
    })

    const tab = ref('')
    const { route } = useRouter()
    const loading = ref(false)
    const shopModel = ref({})

    // tabs
    const tabs = [
      { title: 'Account', icon: mdiAccountOutline },
      { title: 'API Credentials', icon: mdiLockOpenOutline },
    ]

    const showSnackbarMessage = data => {
      snackbar.value.isSnackbarVisible = true
      snackbar.value.snackBarColor = data.type
      snackbar.value.snackbarMessage = data.message
    }

    // account settings data
    async function fetchShop() {
      loading.value = true

      await axios
        .get(`/processing/shops/${route.value.params.id}`)
        .then(response => {
          shopModel.value = response.data
        })
        .catch(error => {
          showSnackbarMessage({ message: error.response, type: 'error' })

          return error
        })
    }

    onMounted(() => {
      fetchShop()
    })

    return {
      fetchShop,
      showSnackbarMessage,
      snackbar,
      tab,
      tabs,
      shopModel,
      loading,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
      },
    }
  },
}
</script>
